import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import './static/css/main.scss';

import Main from './layouts/Main';
import Index from './pages/Index';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';
import Projects from './pages/Projects';
import Resume from './pages/Resume';
import Stats from './pages/Stats';
import About from './pages/About';
import Solutions from './pages/Solutions';
// const About = lazy(() => import('./pages/About'));
const { PUBLIC_URL } = process.env;


function App() {
  return (
    <Router>
      <div className="App">
      <Suspense fallback={<Main />}>
        <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/stats" element={<Stats />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/resume" element={<Resume />} />
        <Route path="/solutions" element={<Solutions />} />
        <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
      </div>
    </Router>
  );
}

export default App;
