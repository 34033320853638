import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Main from '../layouts/Main';

const About = () => {
  return (
    <Main
      title="About"
      description="Learn about Sandeep Maurya"
    >
      <article className="post markdown" id="about">
        <header>
          <div className="title">
            <h2><Link to="/about">About Me</Link></h2>
          </div>
        </header>
        <div>
          <h1 id="intro">Intro</h1>
          <p>
          Experienced backend developer with 12+ years of expertise in Ruby, Ruby on
          Rails, PostgreSQL, MySQL, NodeJS, ExpressJS (TypeScript), and MongoDB.
          Skilled in building scalable applications and implementing microservices
          architectures to enhance reliability and performance.
          <br/><br/>
          With 7+ years of hands-on AWS experience, adept at designing resilient and
          cost-efficient cloud infrastructures. Proficient in AWS services like EC2, ASG,
          EKS, ECS, S3, RDS, Lambda, CloudFront, IAM, CloudWatch, and so on,
          ensuring robust security, performance, and scalability.
          <br/><br/>
          Experienced in managing and leading the complete software development
          lifecycle, with a focus on optimizing application performance, implementing
          robust microservices architectures, and driving cost efficiency. Proficient in
          Docker for containerization, ensuring seamless deployments and environment
          consistency.
          <br/><br/>
          Certified AWS Solutions Architect with expertise in:
          <br/>
          Cost Optimization: Reducing expenses while maximizing resource utilization.<br/>
          Resilience: Building systems for high availability and quick recovery.<br/>
          Operational Excellence: Streamlining processes for consistent outcomes.<br/>
          Performance Efficiency: Optimizing configurations for peak performance.<br/>
          Security: Enforcing data protection and robust security measures.
          <br/><br/>
          Dedicated to delivering secure, scalable, and efficient solutions aligned with
          business goals and industry best practices.
          </p>
          <h1 id="i-like">I like</h1>
          <ul>
            <li>Meditation</li>
            <li>Space</li>
            <li>Programing</li>
            <li>Cloud Infra Managament</li>
            <li>New Challanges</li>
          </ul>
          <h1 id="travel--geography">Travel / Geography</h1>
          <ul>
            <li>I am originally from Uttar Pradesh India.</li>
            <li>I have visited Nepal the Country in 2023, where I would like to revisit.</li>
            <li>From 2008 to 2024, I visited many places in India like Kolkata, Delhi NCR, Mumbai Pune, Nasik, Bengaluru and Chennai.</li>
            <li>In 2008 I Visited Prayagraj to complete my graduation.</li>
            <li>In 2024, I visited Ayodhya Ram mandir.</li>
            </ul>
            <h1 id="i-dream-of">I dream of</h1>
            <ul>
            <li>Staying curious about new technologies.</li>
            <li>Inspiring and feeling inspired.</li>
            <li>Enabling a brighter future for everyone, regardless of political or socioeconomic status.</li>
            <li>Treating every individual with genuine kindness and respect.</li>
            <li>Continually improving.</li>
            </ul>
            </div>
      </article>
    </Main>
  );
};

export default About;
