import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Main from '../layouts/Main';

const Solutions = () => {
  return (
    <Main
      title="Solutions"
      description="Problem and Solutions by Sandeep Maurya"
    >
      <article className="post markdown" id="solutions">
        <header>
          <div className="title">
            <h2><Link to="/solutions">Solutions</Link></h2>
          </div>
        </header>
        <div>
          <h1 id="i-like">Problems & Solutions</h1>
          <ul>
            <li><a href='https://inxspin.com/solutions/ruby'>Ruby</a></li>
            <li><a href='https://inxspin.com/solutions/ruby-on-rails'>Ruby on Rails</a></li>
            <li><a href='https://inxspin.com/solutions/postgresql'>Postgresql</a></li>
            <li><a href='https://inxspin.com/solutions/mongodb'>MongoDB</a></li>
            <li><a href='https://inxspin.com/solutions/aws'>AWS</a></li>
            <li><a href='https://inxspin.com/solutions/nodejs'>Node JS</a></li>
            <li><a href='https://inxspin.com/solutions/reactjs'>React JS</a></li>
            <li><a href='https://inxspin.com/solutions/expressjs'>Express JS</a></li>
            <li><a href='https://inxspin.com/solutions/typescript'>Typescript</a></li>
            <li><a href='https://inxspin.com/solutions/redis'>Redis</a></li>
          </ul>
        </div>
      </article>
    </Main>
  );
};

export default Solutions;
