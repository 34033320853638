/**
 * @typedef {Object} Position
 * Conforms to https://jsonresume.org/schema/
 *
 * @property {string} name - Name of the company
 * @property {string} position - Position title
 * @property {string} url - Company website
 * @property {string} startDate - Start date of the position in YYYY-MM-DD format
 * @property {string|undefined} endDate - End date of the position in YYYY-MM-DD format.
 * If undefined, the position is still active.
 * @property {string|undefined} summary - html/markdown summary of the position
 * @property {string[]} highlights - plain text highlights of the position (bulleted list)
 */
const work = [
  {
    name: 'Opingo (SuperCT Technology Pvt Ltd)',
    position: 'Principal Engineer',
    url: 'https://www.opingo.club/',
    startDate: '2023-07-01',
    endDate: '2024-10-31',
    summary: `AI Based Suspense Social Network.`,
    highlights: [
      'Designed and implemented scalable, resilient, and cost-effective cloud architectures leveraging AWS services such as Auto Scaling Groups, EC2, S3, RDS, ECS, ELB, and Lambda.',
      'Developed APIs using Ruby on Rails, PostgreSQL, NodeJS (ExpressJS + TypeScript), and MongoDB (Amazon DocumentDB) to enable seamless data exchange across applications and third-party services.',
      'Provided technical guidance on cloud adoption strategies, helping stakeholders effectively leverage AWS capabilities.',
      'Optimized PostgreSQL database schemas to enhance data organization and retrieval efficiency.',
      'Conducted code reviews to ensure adherence to best practices, coding standards, and security guidelines.',
    ],
  },
  {
    name: 'CoinDCX (Neblio technologies Pvt Ltd)',
    position: 'Principal Engineer',
    url: 'http://coindcx.com',
    startDate: '2018-04-01',
    endDate: '2023-02-23',
    summary: `Web 3, Crypto Exchange`,
    highlights: [
      'Led the design and architecture of complex software systems, focusing on scalability, performance, and maintainability to meet evolving business needs.',
      'Designed and deployed resilient cloud infrastructures using AWS services, including EC2, ASG, EKS, S3, RDS, Lambda, and API Gateway, achieving cost optimization and system reliability.',
      'Built robust APIs and microservices with Ruby on Rails, PostgreSQL, DynamoDB, MySQL, and NodeJS (ExpressJS + TypeScript) to enable efficient data exchange across applications and services.',
      'Collaborated with senior management to define technical roadmaps, aligning development strategies with business objectives.',
      'Delivered multiple projects within scope and budget while maintaining high technical standards.',
      'Architected multi-region, high-availability solutions, reducing downtime by 30% and enhancing performance.',
      'Integrated automated CI/CD pipelines, reducing deployment errors by 50% and accelerating delivery timelines.',
      'Partnered with cross-functional teams to ensure alignment between cloud strategies and business goals.',
    ],
  },
  {
    name: 'HolaChef Pvt Ltd',
    position: 'Sr Software Engineer',
    url: 'https://holachef.com',
    startDate: '2015-07-01',
    endDate: '2018-03-31',
    summary: `Food Hospitality`,
    highlights: [
      'Design and implement new features, enhancements, and bug fixes in existing software products.',
      'Enhanced software features by leveraging AWS services like Lambda, API Gateway, ECS, and DynamoDB to improve scalability and performance.',
      'Collaborated with architects to optimize system architecture using AWS tools like RDS, S3, and CloudFormation, ensuring alignment with project goals.',
      'Led knowledge-sharing sessions and code reviews to foster best practices and team growth.',
      'Managed small projects, ensuring timely delivery with deployment automation via AWS CodePipeline.',
      'Maintained software quality through automated testing and debugging using CloudWatch Logs and CloudTrail.',
    ],
  },
  {
    name: 'ParityCube Pvt Ltd',
    position: 'Sr Software Engineer',
    url: 'http://paritycube.com',
    startDate: '2015-02-01',
    endDate: '2015-07-30',
    summary: `ParityCube is India's largest online shopping community with more than five lakh members finding and sharing the latest online deals, coupons and offers with each.. Having multiple products like <a href='https://desidime.com'>desidime</a> and <a href='https://zingoy.com'>zingoy</a>`,
    highlights: [
      'Upgraded legacy systems to align with modern standards, improving performance and reliability.',
      'Advocated for new technologies and methodologies to boost team efficiency and product quality.',
      'Ensured compliance with regulatory and data protection standards to safeguard sensitive information.',
      'Optimized application performance for efficient production operations.',
      'Conducted code reviews to enforce coding standards, best practices, andsecurity protocols.',
    ],
  },
  {
    name: 'ARXMind Consultancy Pvt Ltd',
    position: 'Software Engineering',
    url: '#',
    startDate: '2013-10-01',
    endDate: '2014-09-30',
    summary: `ArxMind, is an IT consultancy company that designs, develops, integrates and maintains web, mobile and other applications that helps enterprises to solve ...`,
    highlights: [
      'Designed and architected complex software systems with a focus on scalability, performance, and maintainability.',
      'Developed, tested, and deployed high-quality code aligned with project requirements and organizational standards.',
      'Provided mentorship and guidance to junior developers, fostering skill development and problem-solving capabilities.',
      'Provide guidance and mentorship to less experienced developers, helping them grow their skills and navigate challenges.',
    ],
  },
  {
    name: 'Infocular e-Technologies',
    position: 'Software Engineering',
    url: '#',
    startDate: '2012-07-01',
    endDate: '2013-08-31',
    summary: `We are a Social Engineering Software Group of Integrity and Excellence. We transform ideas into realities and see your business as part of a global social ...`,
    highlights: [
      'Developed responsive and interactive user-facing features using Ruby on Rails, PostgreSQL, CSS, JavaScript, and BackboneJS.',
      'Collaborated with designers to transform UI/UX designs into seamless web pages, enhancing user experience.',
      'Implemented dynamic content features like blogs, news feeds, and user-generated content to boost functionality and engagement.',
      'Troubleshoot and resolve website issues to ensure smooth and reliable operation.',
    ],
  }
];

export default work;
