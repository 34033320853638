import React from 'react';
import { Link } from 'react-router-dom';

import Main from '../layouts/Main';

const Index = () => (
  <Main
    description={"Sandeep Maurya's personal website. Professionaly I am a Sr. Software Engineer. Working as Sr. Ruby on Rails Engineer, Sr AWS Solutions Architect, Sr Software Architect, DevOps Engineer, RPNP & MERN Stack."}
  >
    <article className="post" id="index">
      <header>
        <div className="title">
          <h2><Link to="/">About this site</Link></h2>
        </div>
      </header>
      <p> Welcome to my website. Professionaly I am a Sr. Software Engineer. Working as Sr. Ruby on Rails Engineer, Sr AWS Solutions Architect, Sr Software Architect, DevOps Engineer, RPNP Specialist & MERN Practitioner. Please feel free to read more <Link to="/about">about me</Link>,
        or you can check out my {' '}
        <Link to="/resume">resume</Link>, {' '}
        <Link to="/projects">projects</Link>, {' '}
        view <Link to="/stats">site statistics</Link>, {' '}
        or <Link to="/contact">contact</Link> me.
      </p>
    </article>
  </Main>
);

export default Index;
