const skills = [
  {
    title: 'Ruby',
    competency: 5,
    category: ['Languages', 'Languages'],
  },
  {
    title: 'Ruby on Rails',
    competency: 5,
    category: ['Languages', 'Languages'],
  },
  {
    title: 'Amazon Web Services',
    competency: 5,
    category: ['Tools', 'AWS Cloud'],
  },
  {
    title: 'AWS VPC/Subnet/AZ/NAT Gateway/NACL',
    competency: 5,
    category: ['Tools', 'AWS Cloud'],
  },
  {
    title: 'AWS IAM',
    competency: 5,
    category: ['Tools', 'AWS Cloud'],
  },
  {
    title: 'AWS Secrets Manager',
    competency: 5,
    category: ['Tools', 'AWS Cloud'],
  },
  {
    title: 'AWS KSM',
    competency: 5,
    category: ['Tools', 'AWS Cloud'],
  },
  {
    title: 'AWS SSM',
    competency: 5,
    category: ['Tools', 'AWS Cloud'],
  },
  {
    title: 'AWS Config',
    competency: 5,
    category: ['Tools', 'AWS Cloud'],
  },
  {
    title: 'AWS Kinesis',
    competency: 5,
    category: ['Tools', 'AWS Cloud'],
  },
  {
    title: 'AWS MSK',
    competency: 5,
    category: ['Tools', 'AWS Cloud'],
  },
  {
    title: 'AWS VPC',
    competency: 5,
    category: ['Tools', 'AWS Cloud'],
  },
  {
    title: 'AWS RDS',
    competency: 5,
    category: ['Tools', 'AWS Cloud'],
  },
  {
    title: 'AWS EKS',
    competency: 5,
    category: ['Tools', 'AWS Cloud'],
  },
  {
    title: 'AWS ECS',
    competency: 5,
    category: ['Tools', 'AWS Cloud'],
  },
  {
    title: 'AWS ECR',
    competency: 5,
    category: ['Tools', 'AWS Cloud'],
  },
  {
    title: 'AWS CodeBuild',
    competency: 5,
    category: ['Tools', 'AWS Cloud'],
  },
  {
    title: 'AWS CodePipeline',
    competency: 5,
    category: ['Tools', 'AWS Cloud'],
  },
  {
    title: 'AWS SNS',
    competency: 5,
    category: ['Tools', 'AWS Cloud'],
  },
  {
    title: 'AWS SQS',
    competency: 5,
    category: ['Tools', 'AWS Cloud'],
  },
  {
    title: 'AWS CloudWatch & CloudTrail',
    competency: 5,
    category: ['Tools', 'AWS Cloud'],
  },
  {
    title: 'AWS ELB',
    competency: 5,
    category: ['Tools', 'AWS Cloud'],
  },
  {
    title: 'AWS Autoscaling',
    competency: 5,
    category: ['Tools', 'AWS Cloud'],
  },
  {
    title: 'AWS S3',
    competency: 5,
    category: ['Tools', 'AWS Cloud'],
  },
  {
    title: 'AWS ElasticBeanstalk',
    competency: 5,
    category: ['Tools', 'AWS Cloud'],
  },
  {
    title: 'AWS EC2',
    competency: 5,
    category: ['Tools', 'AWS Cloud'],
  },
  {
    title: 'AWS WAF',
    competency: 5,
    category: ['Tools', 'AWS Cloud'],
  },
  {
    title: 'AWS Shield',
    competency: 5,
    category: ['Tools', 'AWS Cloud'],
  },
  {
    title: 'AWS API Gateway',
    competency: 5,
    category: ['Tools', 'AWS Cloud'],
  },
  {
    title: 'AWS CloudFront CDN',
    competency: 5,
    category: ['Tools', 'AWS Cloud'],
  },
  {
    title: 'AWS Lambda',
    competency: 5,
    category: ['Tools', 'AWS Cloud'],
  },
  {
    title: 'AWS DocumentDB',
    competency: 5,
    category: ['Tools', 'AWS Cloud'],
  },
  {
    title: 'MongoDB',
    competency: 4,
    category: ['Databases', 'Databases'],
  },
  {
    title: 'ElasticSearch',
    competency: 5,
    category: ['Databases', 'Databases'],
  },
  {
    title: 'PostgreSQL',
    competency: 5,
    category: ['Databases', 'Databases'],
  },
  {
    title: 'Redis',
    competency: 5,
    category: ['Databases', 'Databases'],
  },
  {
    title: 'Node.JS',
    competency: 4,
    category: ['Languages', 'Languages'],
  },
  {
    title: 'Express.JS',
    competency: 4,
    category: ['Languages', 'Languages'],
  },
  {
    title: 'Typescript',
    competency: 4,
    category: ['Languages', 'Languages'],
  },
  {
    title: 'HTML + CSS',
    competency: 4,
    category: ['Languages', 'Languages'],
  },
  {
    title: 'Kubernetes',
    competency: 5,
    category: ['Containerization', 'Containerization'],
  },
  {
    title: 'Docker',
    competency: 5,
    category: ['Containerization', 'Containerization'],
  },
  {
    title: 'Terraform',
    competency: 5,
    category: ['Tools', 'Tools'],
  },
  {
    title: 'CI/CD',
    competency: 5,
    category: ['Tools', 'Tools'],
  },
  {
    title: 'Jenkins',
    competency: 5,
    category: ['Tools', 'Tools'],
  },
  {
    title: 'Git',
    competency: 5,
    category: ['Tools', 'Tools'],
  },
  {
    title: 'Bash',
    competency: 4,
    category: ['Tools', 'Tools'],
  },
].map((skill) => ({ ...skill, category: skill.category.sort() }));

// this is a list of colors that I like. The length should be === to the
// number of categories. Re-arrange this list until you find a pattern you like.
const colors = [
  '#6968b3',
  '#37b1f5',
  '#40494e',
  '#515dd4',
  '#e47272',
  '#cc7b94',
  '#3896e2',
  '#c3423f',
  '#d75858',
  '#747fff',
  '#64cb7b',
];

const categories = [
  ...new Set(skills.flatMap(({ category }) => category)),
].sort().map((category, index) => ({
  name: category,
  color: colors[index],
}));

export { categories, skills };
