const degrees = [
  {
    school: 'Allahabad University',
    degree: 'BCA (Bachelor in Computer Application)',
    year: '2008-2011',
  },
  {
    school: 'AWS',
    degree: 'AWS Certified Solutions Architect',
    year: 2021,
  },
];

export default degrees;
