// TODO Add a couple lines about each project
const data = [
  {
    title: 'OpinGo',
    link: 'https://www.opingo.club',
    subtitle: 'AI Based Suspense Social Network',
    image: '/images/projects/opingo.png',
    date: '2024-03-01',
    startDate: '2023-07-01',
    endDate: '2024-07-31',
    current: true,
    desc:
      'Tech Stack: Ruby on Rails, Rspec, NodeJS, Typescript, SpringBoot, PostgreSQL, ChatGpt, Gemini, and Google APIs. AWS - RDS, DocumentDB(MongoDB), ELB, S3, ECS, MSK, CI/CD and so on.'
  },
  {
    title: 'CoinDCX',
    link: 'https://coindcx.com',
    subtitle: 'Crypto Exchange Platform',
    image: '/images/projects/coindcx.png',
    date: '2024-03-01',
    startDate: '2018-04-01',
    endDate: '2023-02-23',
    current: true,
    desc:
      'Tech Stack: Ruby on Rails, Rspec, PostgreSQL, NodeJS, ExpressJS, Typescript, Kafka, MongoDB, Docker, ES, MSK, ECS, EKS, ELB, EC2, ASG, S3, RDS, VPC, WAF , KMS, Lambda CI/CD and many more in AWS.'
  },
  {
    title: 'HolaChef',
    link: 'https://holachef.com',
    subtitle: 'Food Hospitality',
    image: '/images/projects/holachef.png',
    date: '2024-03-01',
    startDate: '2015-07-01',
    endDate: '2018-03-31',
    current: true,
    desc:
      'Tech Stack: Ruby on Rails, Rspec, MySQL, NodeJS, ES, ReactJS and Google APIs. AWS - EC2, ASG, S3, RDS, ELB, CI/CD and so on.'
  },
  {
    title: 'Desidime',
    link: 'https://desidime.com',
    subtitle: 'E-commerce / Affiliate marketing',
    image: '/images/projects/desidime.png',
    date: '2024-03-01',
    startDate: '2015-02-01',
    endDate: '2015-07-30',
    current: true,
    desc:
     'Tech Stack: Ruby on Rails, Rspec, PostgreSQL, NodeJS, Jquery, HTML, JavaScript and CSS.'
  },
  {
    title: 'Zingoy',
    link: 'https://zingoy.com',
    subtitle: 'E-commerce / Affiliate marketing',
    image: '/images/projects/zingoy.png',
    date: '2024-03-01',
    startDate: '2015-02-01',
    endDate: '2015-07-30',
    current: true,
    desc:
      'Tech Stack: Ruby on Rails, Rspec, PostgreSQL, Jquery, HTML JavaScript and CSS.'
  },
  {
    title: 'AI Club',
    link: '#',
    subtitle: 'Celebrity Private Social Platform',
    image: '/images/projects/stem.png',
    date: '2024-03-01',
    startDate: '2013-10-01',
    endDate: '2014-09-30',
    current: true,
    desc:
      'Tech Stack: Ruby on Rails, Rspec, MySQL, PostgreSQL, Jquery, JavaScript, HTML and CSS.'
  },
  {
    title: 'STEM',
    link: '#',
    subtitle: 'Kids Learning Game',
    image: '/images/projects/stem.png',
    date: '2012-07-01',
    startDate: '2012-07-01',
    endDate: '2012-08-30',
    current: true,
    desc:
      'Tech Stack: Ruby on Rails, Rspec, PostgreSQL, Google Blockly, Jquery, HTML, CSS, and Backbone JS.'
  }

];

export default data;
